
const soundDiv = document.getElementById('sound-list')

const soundsList = [
    {
        "tag": "anime,drift,deja,vu",
        "folder": "reactions",
        "id": "R_01",
        "name": "Deja Vu",
        "src": "sfx--deja-vu.mp3",
        "rep": false
    },
    {
        "tag": "final,terminou,fim,galvao,acabou",
        "folder": "reactions",
        "id": "R_02",
        "name": "Acabou (Galvão)",
        "src": "sfx-acabou-galvao.mp3",
        "rep": false
    },
    {
        "tag": "owned,horn,toma,buzina,air",
        "folder": "reactions",
        "id": "R_03",
        "name": "Airhorn",
        "src": "sfx-airhorn.mp3",
        "rep": true
    },
    {
        "tag": "alerta,surpresa,metal,gear,solid",
        "folder": "reactions",
        "id": "R_04",
        "name": <>Alert (Metal Gear Solid)</>,
        "src": "sfx-alert-mgear.mp3",
        "rep": true
    },
    {
        "tag": "suspicious,suspeito,impostor,among,us",
        "folder": "reactions",
        "id": "R_05",
        "name": <>Among Us (start)</>,
        "src": "sfx-among-us.mp3",
        "rep": true
    },
    {
        "tag": "força,atack,cena,john",
        "folder": "reactions",
        "id": "R_06",
        "name": <>John cena</>,
        "src": "sfx-and-his-name-is-john-cena.mp3",
        "rep": false
    },
    {
        "tag": "surprise,anime,wow",
        "folder": "reactions",
        "id": "R_07",
        "name": <>Wow(Anime)</>,
        "src": "sfx-anime-wow.mp3",
        "rep": true
    },
    {
        "tag": "fun,engraçado,morte,dead,morri,ant",
        "folder": "reactions",
        "id": "R_08",
        "name": <>Morri</>,
        "src": "sfx-ant-morri.mp3",
        "rep": true
    },
    {
        "tag": "heroes,avengers,theme,tema,inicio",
        "folder": "reactions",
        "id": "R_09",
        "name": <>Avengers Theme</>,
        "src": "sfx-avengers-theme.mp3",
        "rep": false
    },
    {
        "tag": "saudacao,bom,dia,tioavo",
        "folder": "reactions",
        "id": "R_10",
        "name": <>Bom Dia (uncle)</>,
        "src": "sfx-bom-dia-uncle.mp3",
        "rep": false
    },
    {
        "tag": "tiro,certeiro,acerto,critico,boom,headshot",
        "folder": "reactions",
        "id": "R_11",
        "name": <>Boom headshot</>,
        "src": "sfx-boom-headshot.mp3",
        "rep": false
    },
    {
        "tag": "brother,bruh",
        "folder": "reactions",
        "id": "R_12",
        "name": <>Bruh</>,
        "src": "sfx-bruh.mp3",
        "rep": true
    },
    {
        "tag": "reaction,fun,sad,theme,violine,",
        "folder": "reactions",
        "id": "R_13",
        "name": <>Sad Song (Violine)</>,
        "src": "sfx-cara-de-pedra.mp3",
        "rep": false
    },
    {
        "tag": "cowboy,ratinho,celebracao",
        "folder": "reactions",
        "id": "R_14",
        "name": <>Yeahaaa</>,
        "src": "sfx-comedy-cowboy-yeahaaa.mp3",
        "rep": true
    },
    {
        "tag": "corre,negada,efeito",
        "folder": "reactions",
        "id": "R_15",
        "name": <>Corre Negada</>,
        "src": "sfx-corre-negada.mp3",
        "rep": false
    },
    {
        "tag": "falha,erro,pato",
        "folder": "reactions",
        "id": "R_16",
        "name": <>Cuek</>,
        "src": "sfx-cuek.mp3",
        "rep": true
    },
    {
        "tag": "drama,theme,tema",
        "folder": "reactions",
        "id": "R_17",
        "name": <>Surprise</>,
        "src": "sfx-drama.mp3",
        "rep": false
    },
    {
        "tag": "dramatic,dramatico,reacao",
        "folder": "reactions",
        "id": "R_18",
        "name": <>Dramatic</>,
        "src": "sfx-dramatic.mp3",
        "rep": false
    },
    {
        "tag": "bob,esponja,duas,horas,depois",
        "folder": "reactions",
        "id": "R_19",
        "name": <>Duas Horas Depois</>,
        "src": "sfx-duas-horas-depois.mp3",
        "rep": false
    },
    {
        "tag": "erro,equivoco,equivioco",
        "folder": "reactions",
        "id": "R_20",
        "name": <>Equivico</>,
        "src": "sfx-equivioco.mp3",
        "rep": false
    },
    {
        "tag": "faustao,errou",
        "folder": "reactions",
        "id": "R_21",
        "name": <>Errou!</>,
        "src": "sfx-faustao-errou.mp3",
        "rep": true
    },
    {
        "tag": "fbi,open,up",
        "folder": "reactions",
        "id": "R_23",
        "name": <>FBI Open UP</>,
        "src": "sfx-fbi-open-up.mp3",
        "rep": false
    },
    {
        "tag": "fodeu,phodeu,fudeu,de,vez",
        "folder": "reactions",
        "id": "R_24",
        "name": <>Fudeu de vez</>,
        "src": "sfx-fudeu-de-vez.mp3",
        "rep": false
    },
    {
        "tag": "giga,chat,theme,tema",
        "folder": "reactions",
        "id": "R_25",
        "name": <>Giga Chad</>,
        "src": "sfx-giga-chad-theme.mp3",
        "rep": false
    },
    {
        "tag": "theme,tema,giornos,jojo",
        "folder": "reactions",
        "id": "R_26",
        "name": <>Giornos</>,
        "src": "sfx-giornos-theme.mp3",
        "rep": false
    },
    {
        "tag": "goofy,ahh,falha,erro",
        "folder": "reactions",
        "id": "R_27",
        "name": <>Goofy Escape</>,
        "src": "sfx-goofy-ahh-sounds.mp3",
        "rep": false
    },
    {
        "tag": "aleluia,hallelujah,holy",
        "folder": "reactions",
        "id": "R_28",
        "name": <>Haleluya</>,
        "src": "sfx-hallelujah.mp3",
        "rep": false
    },
    {
        "tag": "bambam,hora,show,porra",
        "folder": "reactions",
        "id": "R_29",
        "name": <>Hora do Show</>,
        "src":"sfx-hora-do-show-porra.mp3",
        "rep": false
    },
    {
        "tag": "divertida,mente,olhou,alguem",
        "folder": "reactions",
        "id": "R_30",
        "name": <>Já olhou</>,
        "src":"sfx-ja-olhou-pra-alguem-e.mp3",
        "rep": false
    },
    {
        "tag": "jacquin,vergonha,profissiao",
        "folder": "reactions",
        "id": "R_31",
        "name": <>Jacquin Vergonha</>,
        "src":"sfx-jacquin-vergonha-da-profissao.mp3",
        "rep": false
    },
    {
        "tag": "tu,dum,pa,bateria",
        "folder": "reactions",
        "id": "R_32",
        "name": <>Tu dum pa!</>,
        "src":"sfx-joke_drum.mp3",
        "rep": true
    },
    {
        "tag": "wow,leroy,jerkins",
        "folder": "reactions",
        "id": "R_33",
        "name": <>Leroy</>,
        "src":"sfx-leroy.mp3",
        "rep": false
    },
    {
        "tag": "lighning,strike,rain",
        "folder": "reactions",
        "id": "R_34",
        "name": <>Lighining</>,
        "src":"sfx-lightning-strike.mp3",
        "rep": false
    },
    {
        "tag": "zelda,link,fada,hey,listen",
        "folder": "reactions",
        "id": "R_35",
        "name": <>Hey Listen</>,
        "src":"sfx-link-hey-listen.mp3",
        "rep": false
    },
    {
        "tag": "mentira,lie",
        "folder": "reactions",
        "id": "R_36",
        "name": <>Mentira!</>,
        "src":"sfx-mentira.mp3",
        "rep": false
    },
    {
        "tag": "cavaleiros,meteoro,phoda,foda,seya",
        "folder": "reactions",
        "id": "R_37",
        "name": <>Meteoro de <b/> Phoda-se</>,
        "src":"sfx-meteoro-de-foda-se.mp3",
        "rep": false
    },
    {
        "tag": "mimimi",
        "folder": "reactions",
        "id": "R_38",
        "name": <>Mimimi</>,
        "src":"sfx-mimimi.mp3",
        "rep": false
    },
    {
        "tag": "netto,num,vai,da",
        "folder": "reactions",
        "id": "R_39",
        "name": <>Num vai dá!!</>,
        "src":"sfx-netto-num-vai-da.mp3",
        "rep": false
    },
    {
        "tag": "nico,anime",
        "folder": "reactions",
        "id": "R_40",
        "name": <>Nico Nico Ni</>,
        "src":"sfx-niconiconi.mp3",
        "rep": false
    },
    {
        "tag": "galvao,ninguem,sabe,oque,faz",
        "folder": "reactions",
        "id": "R_41",
        "name": <>Ninguem sabe o que faz!</>,
        "src":"sfx-ninguem-sabe-oque-faz.mp3",
        "rep": false
    },
    {
        "tag": "star,wars,darth,vader,no",
        "folder": "reactions",
        "id": "R_42",
        "name": <>Nooooo</>,
        "src":"sfx-noooooo.mp3",
        "rep": false
    },
    {
        "tag": "disco,scratch,risco",
        "folder": "reactions",
        "id": "R_43",
        "name": <>Scratch Disc</>,
        "src":"sfx-record-scratch.mp3",
        "rep": false
    },
    {
        "tag": "xfiles,theme,tema",
        "folder": "reactions",
        "id": "R_44",
        "name": <>X-files Theme</>,
        "src":"sfx-x-files.mp3",
        "rep": false
    },
    {
        "tag": "jukera,porra,menor",
        "folder": "reactions",
        "id": "R_45",
        "name": <>Ta porra menó</>,
        "src":"sfx-ta-porra-menor.mp3",
        "rep": false
    },
    {
        "tag": "gta,denovo,again",
        "folder": "reactions",
        "id": "R_46",
        "name": <>Here we go again</>,
        "src":"sfx-shit-here-we-go-again.mp3",
        "rep": false
    },
    {
        "tag": "sad,theme,tema",
        "folder": "reactions",
        "id": "R_47",
        "name": <>Sad Song</>,
        "src":"sfx-sad-sound.mp3",
        "rep": false
    },
    {
        "tag": "funny,porrete,club",
        "folder": "strikes",
        "id": "R_48",
        "name": "bonk",
        "src": "sfx-bonk.mp3",
        "rep": true
    },
    {
        "tag": "discord",
        "id": "discornect",
        "folder": "misc",
        "id": "R_49",
        "name":"Discord_nect",
        "src":"sfx-discord-conect.mp3",
        "rep": true
    },
    {
        "tag": "click",
        "id": "R_50",
        "folder": "misc",
        "name":"CLICK(FUT)",
        "src":"sfx_botao.mp3",
        "rep": true
    },
    {
        "tag": "falha",
        "id": "R_51",
        "folder": "misc",
        "name":"FALHA(FUT)",
        "src":"sfx_fail.mp3",
        "rep": true
    },
    {
        "tag": "roleta",
        "id": "R_52",
        "folder": "misc",
        "name":"ROLETA",
        "folder": "misc",
        "src":"sfx_oleta.mp3",
        "rep": false
    },
    {
        "tag": "seleção",
        "id": "R_53",
        "folder": "misc",
        "name":"SELEÇÃO",
        "src":"sfx_selecao.mp3",
        "rep": true
    },
    {
        "tag": "sucesso",
        "id": "R_54",
        "folder": "misc",
        "name":"SUCESSO",
        "src":"sfx_sucesso_quiz.mp3",
        "rep": false
    },
    {
        "id": "R_55",
        "folder": "misc",
        "name":"WINNER",
        "src":"sfx_sucesso_roleta.mp3",
        "rep": false
    }
]

window.playSound = (type,id)  => {
    try {
        let t = soundsList[type].find(e => e.id == id)
        return new Promise(function(resolve, reject) {   // return a promise
            var audio = new Audio();                     // create audio wo/ src
            audio.preload = "auto";                      // intend to play through
            audio.autoplay = true;                       // autoplay when loaded
            audio.onerror = reject;                      // on error, reject
            audio.onended = resolve;                     // when done, resolve
            audio.src = 'sounds/' + type + '/' + t.src
        });
    } catch (error) {
        console.log('Audio not founded!')
    }
}

    
export default soundsList