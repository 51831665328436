import React, { useEffect, useState } from "react"
import * as Ant from 'antd'
import './sounds.js'

import b_play from '../assets/btn-play.png'
import b_wait from '../assets/btn-wait.png'
import b_stop from '../assets/btn-stop.png'

const playSound = window.playSound
const Button = (props) =>{
    const [isOn, itIsOn] = useState(false)
    const [isPause, itIsPause] = useState(false)
    var audioId = document.getElementById(props.data.id)
    useEffect(() => {
    },[])
    
    const playSound = () => {
        // console.log('Play')
        audioId = document.getElementById(props.data.id)
        audioId.addEventListener('pause',(e) => {
            e.currentTime = 0
            itIsOn(false);
        })
        if(props.data.rep){
            let temp = document.getElementById(props.data.id).cloneNode()
            temp.addEventListener('pause',(e) => {
                e.currentTime = 0
                itIsOn(false);
            })
            temp.play()
            itIsOn(false);
            return true
        }
        audioId.play()
        return true
    }
    const stopSound = () => {
        audioId.pause(); audioId.currentTime = 0;
        audioId = document.getElementById(props.data.id)
    }
    if(!props.data.id) return ''
    return <div className="display-block" style={{backgroundColor:'gray', margin: 5, borderRadius: 15, color: 'white', textAlign:'center'}}>
        <audio src={"sounds/"+props.data.folder+'/'+props.data.src} id={props.data.id} preload="true" autoPlay={false}></audio>
        <div style={{position: 'relative', textAlign:"center",padding: 15}} onClick={() => {
                if(isOn && !props.data.rep){
                    stopSound();itIsOn(false);
                }else {
                    playSound();itIsOn(true);
                }
            }}>
            <div className={`top_corner ${props.data.rep ? "red" : "green"}`} style={{position:'absolute', border: '2px solid white', borderRadius: 14, top:5,left:5,width: 15, fontSize: 10 }}>
                {`${props.data.rep ? "R" : "1"}`}
            </div>
            {/* <span style={{position: 'absolute', transform: "translateY(50%)",top: 0, bottom: 20, left: 0, right: 0, width: '100%', color: 'black', fontSize: 20, textTransform:"uppercase"}} className="btn-text">{isOn && !props.data.rep ? 'Stop' : `Play `}</span> */}
            <img width="60%" height="100%" src={!isOn ? b_play : props.data.rep ? b_wait : b_stop} size="large" className={`button_play ${props.data.rep ? "rept" : "single"}`} >
            </img>
            
        </div>
        <div style={{width:'90%',paddingLeft: '5%'}}>
            <strong>{props.data.name}</strong>
            {/* <p style={{overflow: 'hidden'}}>
                {props.data.tag}
            </p> */}
            <br/>
            <br/>
        </div>
    </div>
}

export default Button

