import { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Buttonsounds from './components/button_sound'
import soundsList from './components/sounds.js'
import * as Ant from 'antd'

function App() {
    const [data, setData] = useState([])
  useEffect(() => {
    let order = soundsList.sort((a,b) => {
        return b.rep - a.rep
    })
    setData(order)
  },[])
  
  const filterData = (s) => {
    if(!s){
        setData(soundsList)
    } else {
        const temp = data.filter((e) => {
            if(e.tag?.includes(s)) {
                console.log('s',e.tag)
                return e
            }
        })
        // console.log(temp)
        setData(temp)
    }
  }

  return (
    <div className="App">
        <div style={{position:"sticky",top:0, backgroundColor:"white", zIndex: 1, borderRadius: 30, padding: 10, textAlign:'center'}}>
            <Ant.Typography.Title level={3}>
                Procurar
                <Ant.Input size="large" allowClear={true} onChange={(e) => filterData(e.target.value)}/>
            </Ant.Typography.Title>
        </div>
        <div>
            {!data.length && <>SEM AUDIO</>}
            {data.map((e,k) => {
                return  <Buttonsounds key={k} data={e} hidden={false}/>
            })}
        </div>
    </div>
  );
}

export default App;
